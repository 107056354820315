import React from 'react';
import styled from '@emotion/styled';

import { ContentContainer } from './CheckoutForm';

import TrialStep1 from 'assets/svgs/trial_step_1.svg';
import TrialStep2 from 'assets/svgs/trial_step_2.svg';
import TrialStep3 from 'assets/svgs/trial_step_3.svg';
import Text from 'components/Text';
import { usePricing } from 'state/PricingStateProvider';

const Title = styled(Text)({
  fontWeight: 600,
  marginBottom: 24,
});

const StepContainer = styled.div({
  display: 'flex',
});

const FlowItem = styled.div({
  paddingTop: 20,
  marginRight: 20,
  minWidth: 34,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const FirstFlowItem = styled(FlowItem)({
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
  backgroundColor: '#E5830E',
});

const SecondFlowItem = styled(FlowItem)({
  background: 'linear-gradient(180deg, #E5830E 10%, rgba(211, 167, 115, 0.446291) 100%)',
});

const ThirdFlowItem = styled(FlowItem)({
  background:
    'linear-gradient(180deg, rgba(211, 167, 115, 0.446291) 5%, rgba(196, 196, 196, 0) 100%)',
});

const StepTexts = styled.div({
  alignSelf: 'stretch',
  paddingTop: 22,
});

const StepDescription = styled(Text)({
  opacity: 0.7,
  lineHeight: '18px',
  marginTop: 4,
});

const TrialInfo: React.FC = () => {
  const { selectedPlanBillingPeriod, selectedPlanPrice } = usePricing();

  return (
    <ContentContainer>
      <Title align="center" type="h2">
        How your trial works
      </Title>
      <StepContainer>
        <FirstFlowItem>
          <TrialStep1 />
        </FirstFlowItem>
        <StepTexts>
          <Text>Today</Text>
          <StepDescription type="small">
            Enjoy full access to premium Yours app content
          </StepDescription>
        </StepTexts>
      </StepContainer>
      <StepContainer>
        <SecondFlowItem>
          <TrialStep2 />
        </SecondFlowItem>
        <StepTexts>
          <Text>Day 1-6</Text>
          <StepDescription type="small">
            Try out 300+ sleep stories, guided meditations, music & sounds of nature
          </StepDescription>
        </StepTexts>
      </StepContainer>
      <StepContainer>
        <ThirdFlowItem>
          <TrialStep3 />
        </ThirdFlowItem>
        <StepTexts>
          <Text>Day 7</Text>
          <StepDescription type="small">
            Free trial ends. You’ll be charged ${selectedPlanPrice}/{selectedPlanBillingPeriod} to
            continue accessing Yours Premium content. Cancel anytime
          </StepDescription>
        </StepTexts>
      </StepContainer>
    </ContentContainer>
  );
};

export default TrialInfo;
