import styled from '@emotion/styled';

const Line = styled.hr({
  borderBottom: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  borderTopColor: 'rgba(255, 255, 255, 0.16)',
  borderTopWidth: '1px',
  height: '1px',
  flexGrow: 5,
  margin: '14px 0',
});

export default Line;
