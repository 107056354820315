import React from 'react';
import styled from '@emotion/styled';

import Text from 'components/Text';

interface IStyleProps {
  isSelected: boolean;
}

interface IContainerProps {
  isNotFirst: boolean;
}

interface IPlan {
  planName: string;
  pricePerMonth: string;
  fullPrice?: string;
  priceText: string;
  onClick: () => void;
  discountPercentage?: number;
  appliedCoupon: string;
}

type TPlan = IPlan & IContainerProps & IStyleProps;

const Flexbox = styled.div({
  display: 'flex',
});

const Container = styled(Flexbox)<IStyleProps & IContainerProps>((props) => ({
  border: `1px solid ${props.isSelected ? props.theme.colors.orange : 'rgba(255, 255, 255, 0.7)'}`,
  borderRadius: 8,
  backgroundColor: props.isSelected ? 'rgba(255, 255, 255, 0.15)' : 'transparent',
  display: 'flex',
  justifyContent: 'space-between',
  padding: 14,
  marginTop: props.isNotFirst ? 26 : 16,
  cursor: 'pointer',
  position: 'relative',
}));

const RadioBubble = styled.div<IStyleProps>((props) => ({
  backgroundColor: props.isSelected ? props.theme.colors.white : 'transparent',
  border: props.isSelected
    ? `7px solid ${props.theme.colors.orange}`
    : `1px solid ${props.theme.colors.white}`,
  width: 20,
  minWidth: 20,
  height: 20,
  boxSizing: 'border-box',
  borderRadius: 10,
  marginRight: 10,
}));

const FullPriceText = styled.span((props) => ({
  color: props.theme.colors.orange,
  textDecoration: 'line-through',
  marginRight: 2,
}));

const PriceText = styled(Text)({
  opacity: 0.7,
  marginTop: 6,
  marginBottom: 12,
});

const PlanName = styled(Text)({
  fontSize: 16,
  fontWeight: 600,
  letterSpacing: 1,
  lineHeight: '24px',
});

const YoursPremiumText = styled(Text)({
  fontWeight: 400,
  letterSpacing: 1,
  lineHeight: '18px',
});

const PriceContainer = styled.div((props) => ({
  color: props.theme.colors.white,
  whiteSpace: 'nowrap',
}));

const Dollar = styled.span({
  verticalAlign: 'top',
  marginRight: 2,
  fontSize: 14,
});

const Price = styled.span({
  fontSize: 22,
  lineHeight: '34px',
});

const Period = styled.span({
  opacity: 0.5,
  fontSize: 10,
  lineHeight: '26px',
  verticalAlign: 'bottom',
});

const DiscountBox = styled(Text)((props) => ({
  backgroundColor: props.theme.colors.orange,
  padding: '6px 18px',
  borderRadius: 25,
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  whiteSpace: 'nowrap',
  bottom: -10,
}));

const Bold = styled.span({
  fontWeight: '700',
});

const Plan: React.FC<TPlan> = ({
  planName,
  pricePerMonth,
  fullPrice,
  priceText,
  isSelected,
  discountPercentage,
  isNotFirst,
  appliedCoupon,
  onClick,
}) => (
  <Container isSelected={isSelected} onClick={onClick} isNotFirst={isNotFirst}>
    <Flexbox>
      <RadioBubble isSelected={isSelected} />
      <div>
        <PlanName>{planName}</PlanName>
        <YoursPremiumText type="small">Yours Premium</YoursPremiumText>
        <PriceText>
          {!!discountPercentage && <FullPriceText>${fullPrice}</FullPriceText>}
          {priceText}
        </PriceText>
      </div>
    </Flexbox>
    <div>
      <PriceContainer>
        <Dollar>&#36;</Dollar>
        <Price>{pricePerMonth}</Price>
        <Period> / month</Period>
      </PriceContainer>
    </div>
    {!!discountPercentage && (
      <DiscountBox type="small">
        {appliedCoupon ? appliedCoupon : 'Limited time deal'} -{' '}
        <Bold>SAVE {discountPercentage}%</Bold>
      </DiscountBox>
    )}
  </Container>
);

export default Plan;
