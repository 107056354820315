import React from 'react';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

import PaypalButton from './PaypalButton';

const Paypal: React.FC = () => {
  const clientId = process.env.GATSBY_PAYPAL_CLIENT_ID;

  if (!clientId) {
    return null;
  }

  return (
    <PayPalScriptProvider options={{ 'client-id': clientId, vault: true }}>
      <PaypalButton />
    </PayPalScriptProvider>
  );
};

export default Paypal;
