import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { AxiosError, AxiosResponse } from 'axios';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import CancellationInfo from './CancellationInfo';

import securePaymentsImg from 'assets/pngs/secure_payments.png';
import Button from 'components/Button';
import Text from 'components/Text';
import { useUpdateUserInfo, useUserInfo } from 'state/UserInfoStateProvider';
import { API_URLS, callCoreApi, METHODS } from 'api';
import { ILoginApiResponse } from 'features/Register/RegisterForm';
import { handlePageView } from 'features/Tracking/trackingApiRequests';
import { usePricing } from 'state/PricingStateProvider';

interface ICardInput {
  isPriceLoading: boolean;
}

export interface ICompletePurchaseApiProps {
  provider_product_id: string;
  payment_method_id: string;
  quantity: number;
  coupon?: string;
}

interface IApiPaymentError {
  detail: string;
}

interface IRefreshTokenApiRequest {
  refresh: string;
}

const InputStyle = {
  flex: 1,
  borderWidth: 0,
  borderRadius: 7,
  padding: '11px 16px',
  marginBottom: '8px 0',
  backgroundColor: 'rgba(255, 255, 255, 0.16)',
};

const CardNumberInput = styled(CardNumberElement)(InputStyle);
const CvcInput = styled(CardCvcElement)(InputStyle);
const ExpiryInput = styled(CardExpiryElement)({
  marginRight: 10,
  ...InputStyle,
});

const SecondaryInputsContainer = styled.div({
  display: 'flex',
  marginTop: 8,
});

const Error = styled(Text)((props) => ({
  color: props.theme.colors.error,
  marginBottom: 12,
}));

const ButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 14,
});

const StyledButton = styled(Button)({
  maxWidth: 229,
});

const SecurePaymentsImageContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  padding: '24px 0 40px 0',
});

const SecurePaymentsImage = styled.img({
  width: '100%',
  maxWidth: 220,
});

const CardInput: React.FC<ICardInput> = ({ isPriceLoading }) => {
  const theme = useTheme();
  const stripe = useStripe();
  const elements = useElements();
  const userInfo = useUserInfo();
  const updateUserInfo = useUpdateUserInfo();
  const { appliedCoupon, stripeProductIdBySelectedPlan } = usePricing();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [paymentError, setPaymentError] = useState<string>('');

  const handlePayment = async () => {
    setLoading(true);
    setPaymentError('');

    if (
      !stripe ||
      !elements ||
      !userInfo?.accessToken ||
      !userInfo?.refreshToken ||
      !stripeProductIdBySelectedPlan
    ) {
      setLoading(false);

      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    if (!cardElement) {
      setLoading(false);

      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error && error?.message) {
      setLoading(false);
      setPaymentError(error.message);
    }

    if (paymentMethod) {
      const coupon = appliedCoupon || undefined;

      callCoreApi<ICompletePurchaseApiProps>({
        method: METHODS.POST,
        url: API_URLS.COMPLETE_PURCHASE,
        accessToken: userInfo?.accessToken,
        data: {
          payment_method_id: paymentMethod.id,
          provider_product_id: stripeProductIdBySelectedPlan,
          quantity: 1,
          coupon,
        },
      })
        .then(() => {
          callCoreApi<IRefreshTokenApiRequest>({
            method: METHODS.POST,
            url: API_URLS.REFRESH_TOKEN,
            data: {
              refresh: userInfo?.refreshToken,
            },
          }).then((response: AxiosResponse<ILoginApiResponse>) => {
            updateUserInfo({
              accessToken: response.data.access,
              refreshToken: response.data.refresh,
            });
            handlePageView('/thank-you');
          });
        })
        .catch((error: AxiosError<IApiPaymentError>) => {
          if (error?.response?.data?.detail) {
            setPaymentError(error?.response?.data?.detail);
          }

          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      <Error>{paymentError}</Error>
      <CardNumberInput
        options={{
          style: {
            base: {
              fontSize: '15px',
              color: theme.colors.white,
              '::placeholder': {
                color: 'rgba(255, 255, 255, 0.5)',
                fontWeight: '400',
              },
            },
          },
        }}
      />
      <SecondaryInputsContainer>
        <ExpiryInput
          options={{
            style: {
              base: {
                fontSize: '15px',
                color: theme.colors.white,
                '::placeholder': {
                  color: 'rgba(255, 255, 255, 0.5)',
                  fontWeight: '400',
                },
              },
            },
          }}
        />
        <CvcInput
          options={{
            style: {
              base: {
                fontSize: '15px',
                color: theme.colors.white,
                '::placeholder': {
                  color: 'rgba(255, 255, 255, 0.5)',
                  fontWeight: '400',
                },
              },
            },
          }}
        />
      </SecondaryInputsContainer>
      <CancellationInfo />
      <ButtonContainer>
        <StyledButton
          isLoading={isPriceLoading || isLoading}
          text="Start Free Trial"
          onClick={handlePayment}
        />
      </ButtonContainer>
      <SecurePaymentsImageContainer>
        <SecurePaymentsImage src={securePaymentsImg} />
      </SecurePaymentsImageContainer>
    </>
  );
};

export default CardInput;
