import React from 'react';
import { Theme, useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import Loader from 'components/Loader';

interface IButton {
  onClick: () => void;
  text: string;
  isDisabled?: boolean;
  isLoading?: boolean;
}

interface IDisabledProps {
  isDisabledStyle?: boolean;
}

interface IButtonTypeProps {
  buttonType?: TButtonType;
}

type TButtonType = 'primary' | 'secondary';

type TButton = IButton & IDisabledProps & IButtonTypeProps;

interface IGetStyleByType {
  type?: TButtonType;
  theme: Theme;
  isDisabledStyle?: boolean;
}

export const getStyleByType = ({ type = 'primary', theme, isDisabledStyle }: IGetStyleByType) =>
  ({
    primary: {
      border: isDisabledStyle ? '1px solid rgba(255, 255, 255, 0.27)' : 'none',
      backgroundColor: isDisabledStyle ? 'transparent' : theme.colors.orange,
      ':hover': {
        cursor: isDisabledStyle ? 'unset' : 'pointer',
        backgroundColor: isDisabledStyle ? 'transparent' : theme.colors.buttonOrangeHover,
      },
    },
    secondary: {
      border: `1px solid ${
        isDisabledStyle ? 'rgba(177, 177, 177, 0.27)' : 'rgba(205, 208, 209, 0.27)'
      }`,
      backgroundColor: 'transparent',
      ':hover': {
        cursor: isDisabledStyle ? 'unset' : 'pointer',
        borderColor: isDisabledStyle ? '1px solid rgba(177, 177, 177, 0.27)' : theme.colors.white,
      },
    },
  }[type]);

const StyledButton = styled.button<IDisabledProps & IButtonTypeProps>(
  ({ theme, buttonType, isDisabledStyle }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: isDisabledStyle ? theme.colors.buttonGray : theme.colors.white,
    height: '43px',
    borderRadius: '100px',
    outline: 'none',
    width: '100%',
    fontSize: '18px',
    fontFamily: 'Poppins',
    ...getStyleByType({
      theme: theme,
      type: buttonType,
      isDisabledStyle,
    }),
  })
);

const Button: React.FC<TButton> = ({
  onClick,
  text,
  isDisabled,
  isLoading,
  buttonType = 'primary',
  ...rest
}) => {
  const theme = useTheme();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    !!e && e.preventDefault();

    onClick();
  };

  const disabled = isDisabled || isLoading;

  return (
    <StyledButton
      buttonType={buttonType}
      onClick={handleClick}
      disabled={disabled}
      isDisabledStyle={isDisabled}
      {...rest}
    >
      {isLoading ? <Loader color={theme.colors.white} /> : text}
    </StyledButton>
  );
};

export default Button;
