import React from 'react';
import styled from '@emotion/styled';

import PaypalButton from 'features/Paypal';
import CcIcon from 'assets/svgs/cc_icon.svg';

const Container = styled.div({
  display: 'flex',
  marginTop: 16,
});

const CreditCard = styled.div((props) => ({
  backgroundColor: props.theme.colors.white,
  color: props.theme.colors.darkText,
  display: 'flex',
  flex: 1,
  height: 40,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 4,
  fontSize: 13,
  fontWeight: 600,
  lineHeight: '24px',
}));

const PaypalButtonContainer = styled.div({
  flex: 1,
});

const StyledCcIcon = styled(CcIcon)({
  marginRight: 4,
});

const PaymentTypeChoice: React.FC = () => (
  <Container>
    <CreditCard>
      <StyledCcIcon />
      Credit Card
    </CreditCard>
    <PaypalButtonContainer>
      <PaypalButton />
    </PaypalButtonContainer>
  </Container>
);

export default PaymentTypeChoice;
