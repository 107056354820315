import React, { useState } from 'react';
import styled from '@emotion/styled';

type TInputType = 'password' | 'number' | 'email' | 'text';

interface IInput {
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
  type?: TInputType;
  min?: number;
  autoCapitalize?: 'none';
}

interface IErrorProp {
  error?: string;
}

type TInput = IInput & IErrorProp;

const StyledInput = styled.input((props) => ({
  border: 'none',
  borderRadius: 7,
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  fontSize: '16px',
  fontWeight: 500,
  outline: 'none',
  width: '100%',
  textIndent: '16px',
  padding: '10px 0',
  color: props.theme.colors.white,
  '::placeholder': {
    color: 'rgba(255, 255, 255, 0.5)',
  },
}));

const Input: React.FC<TInput> = ({
  onChange,
  value,
  type,
  placeholder,
  min,
  autoCapitalize,
  ...rest
}) => {
  const [isInputFocused, setInputFocused] = useState(false);

  const onInputFocus = () => {
    setInputFocused(true);
  };

  const onInputBlur = () => {
    setInputFocused(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <StyledInput
      onChange={handleChange}
      value={value}
      type={type}
      placeholder={isInputFocused ? '' : placeholder}
      onFocus={onInputFocus}
      onBlur={onInputBlur}
      min={min}
      autoCapitalize={autoCapitalize}
      {...rest}
    />
  );
};

export default Input;
