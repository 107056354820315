import React, { useRef } from 'react';
import styled from '@emotion/styled';

import { ICouponModal } from './ApplyCoupon';
import CloseButton from './CloseButton';

import ErrorIcon from 'assets/svgs/error.svg';
import Text from 'components/Text';
import Input from 'components/Input';
import Button from 'components/Button';
import useOnOutsideClick from 'utils/useOnOutsideClick';

interface IStyledText {
  isMarginBottom: boolean;
}

const Overlay = styled.div({
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: 110,
  backgroundColor: 'rgba(23, 33, 38, 0.7)',
});

const Modal = styled.div((props) => ({
  position: 'fixed',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: props.theme.colors.backgroundGray,
  padding: '45px 114px',
  width: '100%',
  maxWidth: 290,
  zIndex: 111,
}));

const ModalContent = styled.div({
  position: 'relative',
});

const ButtonsContainer = styled.div({
  display: 'flex',
  marginTop: 20,
});

const StyledText = styled(Text)<IStyledText>((props) => ({
  fontSize: 22,
  lineHeight: '33px',
  fontWeight: 600,
  marginBottom: props.isMarginBottom ? 24 : 0,
}));

const StyledInput = styled(Input)({
  textTransform: 'uppercase',
});

const CancelButton = styled(Button)({
  background: 'none',
  opacity: 0.6,
  ':hover': {
    background: 'none',
    opacity: 1,
  },
});

const ErrorContainer = styled.div({
  backgroundColor: 'rgba(244, 67, 54, 0.2)',
  padding: '3px 10px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 4,
  marginBottom: 10,
  marginTop: 12,
});

const ErrorText = styled(Text)((props) => ({
  color: props.theme.colors.error,
  marginLeft: 8,
}));

const DesktopModal: React.FC<ICouponModal> = ({
  onApply,
  onClose,
  onInputChange,
  isLoading,
  inputValue,
  error,
}) => {
  const ref = useRef(null);
  useOnOutsideClick(ref, onClose);

  return (
    <>
      <Overlay />
      <Modal ref={ref}>
        <ModalContent>
          <CloseButton onClick={onClose} />
          <StyledText isMarginBottom={!error}>Enter your coupon code</StyledText>
          {!!error && (
            <ErrorContainer>
              <ErrorIcon />
              <ErrorText>{error}</ErrorText>
            </ErrorContainer>
          )}
          <StyledInput placeholder="Enter coupon" value={inputValue} onChange={onInputChange} />
          <ButtonsContainer>
            <Button text="Apply" onClick={onApply} isLoading={isLoading} />
            <CancelButton text="Cancel" onClick={onClose} />
          </ButtonsContainer>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DesktopModal;
