import React from 'react';
import styled from '@emotion/styled';

import CloseX from 'assets/svgs/close_x.svg';

const Button = styled.button({
  position: 'absolute',
  backgroundColor: 'rgba(255, 255, 255, 0.08)',
  border: 'none',
  outline: 'none',
  borderRadius: 17,
  top: -34,
  right: -98,
  height: 34,
  width: 34,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  cursor: 'pointer',
});

const CloseButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ ...rest }) => (
  <Button {...rest}>
    <CloseX />
  </Button>
);

export default CloseButton;
