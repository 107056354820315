import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

interface IStyleProps {
  color?: string;
}

const ldsRing = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Container = styled.div<IStyleProps>`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 2px;
    border: 2px solid ${(props) => props.color || props.theme.colors.darkText};
    border-radius: 50%;
    animation: ${ldsRing} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) => props.color || props.theme.colors.darkText} transparent transparent
      transparent;
  }

  div:nth-of-type(1) {
    animation-delay: -0.45s;
  }
  div:nth-of-type(2) {
    animation-delay: -0.3s;
  }
  div:nth-of-type(3) {
    animation-delay: -0.15s;
  }
`;

const Loader: React.FC<IStyleProps> = ({ color, ...rest }) => (
  <Container color={color} {...rest}>
    <div />
    <div />
    <div />
    <div />
  </Container>
);

export default Loader;
