import React from 'react';
import styled from '@emotion/styled';

import Text from 'components/Text';

const Container = styled.div({
  marginTop: 24,
});

const MainText = styled(Text)({
  fontWeight: 500,
  marginBottom: 8,
});

const SecondaryText = styled(Text)({
  opacity: 0.8,
});

const CancellationInfo: React.FC = () => (
  <Container>
    <MainText align="center" type="small">
      Cancel anytime
    </MainText>
    <SecondaryText align="center" type="small">
      You won’t be charged until after your free trial
    </SecondaryText>
  </Container>
);

export default CancellationInfo;
