import React from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { FUNDING, PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';

import { ILoginApiResponse } from 'features/Register/RegisterForm';
import Loader from 'components/Loader';
import { API_URLS, callCoreApi, METHODS } from 'api';
import { handlePageView } from 'features/Tracking/trackingApiRequests';
import { useUpdateUserInfo, useUserInfo } from 'state/UserInfoStateProvider';
import { COOKIE_KEY, getCookie } from 'utils/cookies';
import { usePricing } from 'state/PricingStateProvider';

interface ICreatePaypalSubscriptionData {
  provider_product_id: string;
  coupon?: string;
}

interface ICreatePaypalSubscriptionResponse {
  id: string;
  status: string;
  create_time: string;
}

interface IOnApproveData {
  subscriptionID?: string | null;
}

interface IRefreshTokenApiRequest {
  refresh: string;
}

const PaypalButton: React.FC = () => {
  const { paypalProductIdBySelectedPlan } = usePricing();

  if (!paypalProductIdBySelectedPlan) {
    return null;
  }

  const [{ isPending }] = usePayPalScriptReducer();
  const updateUserInfo = useUpdateUserInfo();
  const userInfo = useUserInfo();

  const createSubscription = () => {
    const coupon = getCookie(COOKIE_KEY.COUPON) || undefined;

    return callCoreApi<ICreatePaypalSubscriptionData>({
      method: METHODS.POST,
      url: API_URLS.CREATE_PAYPAL_SUBSCRIPTION,
      accessToken: userInfo?.accessToken,
      data: {
        provider_product_id: paypalProductIdBySelectedPlan,
        coupon,
      },
    })
      .then((response: AxiosResponse<ICreatePaypalSubscriptionResponse>) => {
        return response?.data?.id;
      })
      .catch((error: AxiosError) => {
        alert('Something went wrong');
        console.log({ error });

        return '';
      });
  };

  const onApprove = async (data: IOnApproveData) => {
    if (!userInfo) {
      return;
    }

    try {
      await callCoreApi({
        method: METHODS.POST,
        url: API_URLS.CREATE_PAYPAL_SUBSCRIPTION,
        path: `${data.subscriptionID}/capture/`,
        accessToken: userInfo?.accessToken,
      });

      const response: AxiosResponse<ILoginApiResponse> = await callCoreApi<IRefreshTokenApiRequest>(
        {
          method: METHODS.POST,
          url: API_URLS.REFRESH_TOKEN,
          data: {
            refresh: userInfo?.refreshToken,
          },
        }
      );

      updateUserInfo({
        accessToken: response.data.access,
        refreshToken: response.data.refresh,
      });

      handlePageView('/thank-you');
    } catch (error) {
      alert('Something went wrong');
      console.log({ error });
    }
  };

  return (
    <>
      {!isPending && (
        <PayPalButtons
          forceReRender={[paypalProductIdBySelectedPlan]}
          createSubscription={createSubscription}
          onApprove={onApprove}
          fundingSource={FUNDING.PAYPAL}
          style={{
            color: 'black',
            height: 40,
          }}
        />
      )}
      {isPending && <Loader />}
    </>
  );
};

export default PaypalButton;
