import React from 'react';
import styled from '@emotion/styled';

import { ContentContainer } from './CheckoutForm';
import TrialInfo from './TrialInfo';

import Text from 'components/Text';
import AppleReview from 'assets/pngs/apple_review.png';
import GoogleReview from 'assets/pngs/google_review.png';
import MoneyBackGuarantee from 'assets/svgs/money_back.svg';
import Meditation from 'assets/svgs/meditation.svg';
import ActiveUser from 'assets/svgs/active_user.svg';
import { BREAKPOINTS } from 'utils/constants';

const MoneyBackText = styled(Text)({
  fontWeight: 600,
  marginTop: 22,
  marginBottom: 16,
});

const MoneyBackDescription = styled(Text)({
  opacity: 0.7,
  fontWeight: 400,
});

const StyledContainer = styled(ContentContainer)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const ReviewsContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

const ReviewComponentContainer = styled.div({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
});

const GoogleReviewComponentContainer = styled(ReviewComponentContainer)({
  display: 'flex',
  position: 'relative',
  ':before': {
    content: '" "',
    position: 'absolute',
    height: '60%',
    top: '20%',
    left: 0,
    width: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
});

const ReviewComponent = styled.img({
  width: 120,
});

const StatsContainer = styled.div({
  display: 'flex',
  alignSelf: 'stretch',
  marginTop: 8,
});

const Stats = styled(ContentContainer)({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 0,
  [BREAKPOINTS[500]]: {
    marginRight: 0,
  },
});

const StatTitle = styled(Text)({
  fontSize: 20,
  fontWeight: 600,
  lineHeight: '20px',
  marginTop: 10,
});

const MindfulMinutesTitle = styled(StatTitle)({
  marginTop: 24,
});

const StatDescription = styled(Text)({
  opacity: 0.7,
  fontWeight: 400,
  marginTop: 4,
});

const ActiveUserStats = styled(Stats)({
  marginLeft: 8,
  [BREAKPOINTS[500]]: {
    marginLeft: 8,
    marginRight: 20,
  },
});

const CheckoutInfo: React.FC = () => (
  <>
    <StyledContainer>
      <MoneyBackGuarantee />
      <MoneyBackText align="center" type="h2">
        30-day money-back guarantee
      </MoneyBackText>
      <MoneyBackDescription align="center">
        If you are not happy with Yours app, please contact us to receive a full refund within 30
        days.
      </MoneyBackDescription>
    </StyledContainer>
    <TrialInfo />
    <ContentContainer>
      <ReviewsContainer>
        <ReviewComponentContainer>
          <ReviewComponent src={AppleReview} />
        </ReviewComponentContainer>
        <GoogleReviewComponentContainer>
          <ReviewComponent src={GoogleReview} />
        </GoogleReviewComponentContainer>
      </ReviewsContainer>
    </ContentContainer>
    <StatsContainer>
      <Stats>
        <Meditation />
        <MindfulMinutesTitle align="center">2m+</MindfulMinutesTitle>
        <StatDescription align="center" type="small">
          Mindful minutes
        </StatDescription>
      </Stats>
      <ActiveUserStats>
        <ActiveUser />
        <StatTitle align="center">25k+</StatTitle>
        <StatDescription align="center" type="small">
          Active users
        </StatDescription>
      </ActiveUserStats>
    </StatsContainer>
  </>
);

export default CheckoutInfo;
