import React, { useState } from 'react';
import { AxiosResponse } from 'axios';

import DesktopModal from './DesktopModal';
import MobileModal from './MobileModal';

import { useWindowSize } from 'utils/customHooks';
import { usePricing } from 'state/PricingStateProvider';
import { useUserInfo } from 'state/UserInfoStateProvider';
import { API_URLS, callCoreApi, METHODS } from 'api';
import { setCookie, removeCookie, COOKIE_KEY } from 'utils/cookies';

interface IApplyCoupon {
  isVisible: boolean;
  onClose: () => void;
}

export interface ICheckCouponResponse {
  amount_off: number;
  percent_off: number;
  trial_period_days: number;
}

export interface ICouponModal {
  onClose: () => void;
  onInputChange: (value: string) => void;
  onApply: () => void;
  isLoading: boolean;
  inputValue: string;
  error: string;
}

const ApplyCoupon: React.FC<IApplyCoupon> = ({ isVisible, onClose }) => {
  const userInfo = useUserInfo();
  const { setAppliedCoupon, appliedCoupon, setTrialLength } = usePricing();
  const { width } = useWindowSize();

  const [isCouponLoading, setCouponLoading] = useState(false);
  const [error, setError] = useState('');
  const [value, setValue] = useState(appliedCoupon);

  const handleInputChange = (value: string) => {
    setValue(value);
  };

  const handleCouponApply = () => {
    setCouponLoading(true);

    if (!value) {
      setCouponLoading(false);
      onClose();
      setAppliedCoupon('');
      removeCookie(COOKIE_KEY.COUPON);

      return;
    }

    const formattedValue = value.toUpperCase().trim();

    callCoreApi({
      method: METHODS.POST,
      url: API_URLS.CHECKOUT_COUPONS,
      path: `${formattedValue}/validate/`,
      accessToken: userInfo?.accessToken,
    })
      .then((response: AxiosResponse<ICheckCouponResponse>) => {
        const trialLength = response?.data?.trial_period_days;

        if (trialLength) {
          setTrialLength(trialLength);
        }

        setAppliedCoupon(formattedValue);
        onClose();
        setError('');
        setCookie(COOKIE_KEY.COUPON, formattedValue);
      })
      .catch(() => {
        setError(`Coupon "${formattedValue}" is not valid.`);
      })
      .finally(() => {
        setCouponLoading(false);
      });
  };

  if (!isVisible) {
    return null;
  }

  if (width && width > 800) {
    return (
      <DesktopModal
        onClose={onClose}
        onApply={handleCouponApply}
        onInputChange={handleInputChange}
        inputValue={value}
        isLoading={isCouponLoading}
        error={error}
      />
    );
  }

  if (width && width <= 800) {
    return (
      <MobileModal
        onClose={onClose}
        onApply={handleCouponApply}
        onInputChange={handleInputChange}
        inputValue={value}
        isLoading={isCouponLoading}
        error={error}
      />
    );
  }

  return null;
};

export default ApplyCoupon;
