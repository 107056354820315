import React, { useEffect } from 'react';
import styled from '@emotion/styled';

import mobileBackground from 'assets/pngs/mobile_background.jpg';
import PrivateComponent from 'navigation/PrivateComponent';
import CheckoutForm from 'features/Checkout/CheckoutForm';
import CheckoutInfo from 'features/Checkout/CheckoutInfo';
import Benefits from 'features/Checkout/Benefits';
import FullScreenWrapper from 'components/FullScreenWrapper';
import Block from 'components/SummaryContentBlock';
import { handlePageView } from 'features/Tracking/trackingApiRequests';
import { BREAKPOINTS } from 'utils/constants';

const StyledWrapper = styled(FullScreenWrapper)({
  background:
    'linear-gradient(180deg, #0C4C7A -1.35%, #386A8C 15.61%, #4D7895 27.1%, #4D7895 53.45%, #3B4D70 103.49%)',
  [BREAKPOINTS[500]]: {
    padding: 0,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
});

const StyledBlock = styled(Block)({
  marginTop: 132,
  marginBottom: 32,
  paddingLeft: 42,
  paddingRight: 42,
  [BREAKPOINTS[500]]: {
    marginTop: 0,
    padding: 0,
    minHeight: '100vh',
    backgroundColor: 'transparent',
  },
});

const Checkout: React.FC = () => {
  useEffect(() => {
    handlePageView('/premium-offer');
  }, []);

  return (
    <PrivateComponent>
      <StyledWrapper mobileBackgroundImage={mobileBackground}>
        <StyledBlock>
          <Benefits />
          <CheckoutForm />
          <CheckoutInfo />
        </StyledBlock>
      </StyledWrapper>
    </PrivateComponent>
  );
};

export default Checkout;
