import React, { useState } from 'react';
import styled from '@emotion/styled';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import CardInput from './CardInput';
import SelectPlan from './SelectPlan';
import PaymentTypeChoice from './PaymentTypeChoice';

import ApplyCoupon from 'features/ApplyCoupon';
import AcceptedCards from 'assets/pngs/accepted_cards.png';
import ProtectedPayment from 'assets/svgs/protected_payment.svg';
import { BREAKPOINTS } from 'utils/constants';

export const ContentContainer = styled.div({
  backgroundColor: 'rgba(23, 33, 38)',
  borderRadius: 15,
  padding: '32px 22px',
  alignSelf: 'stretch',
  marginTop: 8,
  [BREAKPOINTS[500]]: {
    padding: '32px 20px',
    marginLeft: 20,
    marginRight: 20,
  },
});

const CheckoutContainer = styled(ContentContainer)({
  marginTop: 32,
  textAlign: 'center',
});

const PaymentInfo = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '16px 0 24px 0',
});

const Cards = styled.img({
  width: 132,
  pointerEvents: 'none',
});

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY as string);

const CheckoutForm: React.FC = () => {
  const [isPriceLoading, setPriceLoading] = useState(true);
  const [isCouponModalVisible, setCouponModalVisible] = useState(false);

  const handleGotCouponOpen = () => {
    setCouponModalVisible(true);
    document.body.style.overflowY = 'hidden';
  };

  const handleGotCouponClose = () => {
    setCouponModalVisible(false);
    document.body.style.overflowY = '';
  };

  return (
    <CheckoutContainer>
      <ApplyCoupon isVisible={isCouponModalVisible} onClose={handleGotCouponClose} />
      <SelectPlan
        onPriceLoadStart={() => setPriceLoading(true)}
        onPriceLoadEnd={() => setPriceLoading(false)}
        onGotCouponClick={handleGotCouponOpen}
      />
      <PaymentTypeChoice />
      <PaymentInfo>
        <Cards src={AcceptedCards} />
        <ProtectedPayment />
      </PaymentInfo>
      <Elements stripe={stripePromise}>
        <CardInput isPriceLoading={isPriceLoading} />
      </Elements>
    </CheckoutContainer>
  );
};

export default CheckoutForm;
