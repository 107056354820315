import React from 'react';
import styled from '@emotion/styled';

import { ICouponModal } from './ApplyCoupon';

import ErrorIcon from 'assets/svgs/error.svg';
import backgroundImg from 'assets/pngs/special_deal_background.jpg';
import Input from 'components/Input';
import Text from 'components/Text';
import Button from 'components/Button';

interface IStyledText {
  isMarginBottom: boolean;
}

const Container = styled.div({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 110,
  display: 'flex',
  flexDirection: 'column',
  backgroundImage: `url(${backgroundImg})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  padding: '36px 32px',
});

const StyledInput = styled(Input)({
  textTransform: 'uppercase',
  marginBottom: 10,
});

const StyledButton = styled(Button)({
  maxWidth: 230,
  marginTop: 12,
  alignSelf: 'center',
});

const CancelButton = styled(StyledButton)({
  background: 'none',
  opacity: 0.6,
  ':hover': {
    background: 'none',
    opacity: 1,
  },
});

const ErrorContainer = styled.div({
  backgroundColor: 'rgba(244, 67, 54, 0.2)',
  padding: '3px 10px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 4,
  marginBottom: 10,
  marginTop: 12,
});

const ErrorText = styled(Text)((props) => ({
  color: props.theme.colors.error,
  marginLeft: 8,
}));

const StyledText = styled(Text)<IStyledText>((props) => ({
  fontSize: 22,
  lineHeight: '33px',
  fontWeight: 600,
  marginBottom: props.isMarginBottom ? 24 : 0,
}));

const DesktopModal: React.FC<ICouponModal> = ({
  onApply,
  onClose,
  onInputChange,
  inputValue,
  error,
  isLoading,
}) => (
  <Container>
    <StyledText isMarginBottom={!error}>Enter your coupon code</StyledText>
    {!!error && (
      <ErrorContainer>
        <ErrorIcon />
        <ErrorText>{error}</ErrorText>
      </ErrorContainer>
    )}
    <StyledInput placeholder="Enter coupon" value={inputValue} onChange={onInputChange} />
    <StyledButton isLoading={isLoading} text="Apply" onClick={onApply} />
    <CancelButton text="Cancel" onClick={onClose} />
  </Container>
);

export default DesktopModal;
