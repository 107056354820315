import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { AxiosResponse } from 'axios';

import Plan from './Plan';

import CouponSuccess from 'assets/svgs/coupon_success.svg';
import Text from 'components/Text';
import Loader from 'components/Loader';
import Line from 'components/Line';
import { ICheckCouponResponse } from 'features/ApplyCoupon/ApplyCoupon';
import { removeCookie, COOKIE_KEY } from 'utils/cookies';
import { API_URLS, callCoreApi, METHODS } from 'api';
import { useUserInfo } from 'state/UserInfoStateProvider';
import { usePricing, IPlan } from 'state/PricingStateProvider';

interface ISelectPlan {
  onPriceLoadEnd: () => void;
  onPriceLoadStart: () => void;
  onGotCouponClick: () => void;
}

interface IGetPriceApiProps {
  provider_product_id: string;
  quantity: number;
  coupon?: string;
}

const CouponContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 16,
  marginTop: 24,
});

const DiscountActionContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 6,
});

const DiscountAction = styled.button((props) => ({
  background: 'none',
  textDecoration: 'underline',
  fontWeight: '400',
  color: props.theme.colors.white,
  opacity: 0.6,
  fontSize: 12,
  lineHeight: '18px',
  outline: 'none',
  border: 'none',
  padding: 0,
  cursor: 'pointer',
}));

const SecondaryPrice = styled(Text)((props) => ({
  color: props.theme.colors.grayText,
  fontWeight: 400,
}));

const Coupon = styled(SecondaryPrice)({
  display: 'flex',
});

const StyledCouponSuccessIcon = styled(CouponSuccess)({
  marginLeft: 4,
});

const StyledLine = styled(Line)({
  marginTop: 24,
  marginBottom: 32,
});

const TodaysPrice = styled(Text)((props) => ({
  color: props.theme.colors.grayText,
  fontSize: 20,
  fontWeight: 600,
  lineHeight: '30px',
  marginBottom: 6,
}));

const PriceInfo = styled(Text)((props) => ({
  color: props.theme.colors.white,
  opacity: 0.7,
  fontSize: 12,
  lineHeight: '18px',
}));

const SelectPlanText = styled(Text)({
  marginTop: 40,
  marginBottom: 16,
  fontSize: 16,
  fontWeight: 600,
  lineHeight: '24px',
});

const LoaderContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '40px 0',
});

const SelectPlan: React.FC<ISelectPlan> = ({
  onPriceLoadStart,
  onPriceLoadEnd,
  onGotCouponClick,
}) => {
  const user = useUserInfo();
  const {
    plans,
    appliedCoupon,
    selectedPlanPrice,
    selectedPlanBillingPeriod,
    selectedPlanId,
    trialLength,
    setAppliedCoupon,
    resetPlans,
    setPlans,
    setSelectedPlanId,
    setTrialLength,
  } = usePricing();

  useEffect(() => {
    if (!appliedCoupon) {
      return;
    }

    callCoreApi({
      method: METHODS.POST,
      url: API_URLS.CHECKOUT_COUPONS,
      path: `${appliedCoupon}/validate/`,
      accessToken: user?.accessToken,
    })
      .then((response: AxiosResponse<ICheckCouponResponse>) => {
        const trialLengthByCoupon = response?.data?.trial_period_days;

        if (trialLengthByCoupon) {
          setTrialLength(trialLengthByCoupon);
        }
      })
      .catch(() => {
        handleCouponCancelClick();
      });
  }, []);

  useEffect(() => {
    onPriceLoadStart();

    if (!user?.accessToken) {
      onPriceLoadEnd();
      return;
    }

    resetPlans();

    callCoreApi<IGetPriceApiProps>({
      method: METHODS.GET,
      url: API_URLS.GET_PLANS,
      accessToken: user?.accessToken,
      queryParams: appliedCoupon ? [{ key: 'coupon', value: appliedCoupon }] : [],
    }).then((response: AxiosResponse<IPlan[]>) => {
      setPlans(response?.data);
      setSelectedPlanId(response?.data?.[0]?.id);
      onPriceLoadEnd();
    });
  }, [appliedCoupon]);

  const handleCouponCancelClick = () => {
    setAppliedCoupon('');
    setTrialLength(7);
    removeCookie(COOKIE_KEY.COUPON);
  };

  const onStudentClick = () => {
    window.open('https://connect.studentbeans.com/v4/hosted/yours-app/us');
  };

  return (
    <>
      <TodaysPrice align="center">Total due today: $0.00</TodaysPrice>
      <PriceInfo align="center">
        Try {trialLength} days free, then ${selectedPlanPrice}/{selectedPlanBillingPeriod}
      </PriceInfo>
      <PriceInfo align="center">Cancel anytime.</PriceInfo>
      <SelectPlanText align="center">Select your plan</SelectPlanText>
      {!plans && (
        <LoaderContainer>
          <Loader color="white" />
        </LoaderContainer>
      )}
      {plans?.map((plan, index) => (
        <Plan
          isNotFirst={index !== 0}
          key={plan.id}
          planName={plan.name}
          pricePerMonth={plan.monthly_price.toFixed(2)}
          fullPrice={plan.full_price.toFixed(2)}
          priceText={`$${plan.price.toFixed(2)} billed every ${plan.billing_period}`}
          onClick={() => setSelectedPlanId(plan.id)}
          isSelected={selectedPlanId === plan.id}
          discountPercentage={plan.discount_percent}
          appliedCoupon={appliedCoupon}
        />
      ))}
      {appliedCoupon && (
        <CouponContainer>
          <Coupon>
            {appliedCoupon}
            <StyledCouponSuccessIcon />
          </Coupon>
          <DiscountAction onClick={handleCouponCancelClick}>Cancel</DiscountAction>
        </CouponContainer>
      )}
      {!appliedCoupon && (
        <DiscountActionContainer>
          <DiscountAction onClick={onGotCouponClick}>Got coupon?</DiscountAction>
        </DiscountActionContainer>
      )}
      <DiscountActionContainer>
        <DiscountAction onClick={onStudentClick}>Are you a student?</DiscountAction>
      </DiscountActionContainer>
      <StyledLine />
    </>
  );
};

export default SelectPlan;
