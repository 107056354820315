import styled from '@emotion/styled';

interface ITextProps {
  type?: 'small' | 'p' | 'h1' | 'h2';
  weight?: 'bold' | 'normal' | 'bolder';
  align?: 'center' | 'left' | 'right';
}

export const getStyleByType = (type: 'p' | 'h1' | 'h2' | 'small') =>
  ({
    h1: {
      fontSize: '24px',
    },
    h2: {
      fontSize: '18px',
    },
    p: {
      fontSize: '15px',
    },
    small: {
      fontSize: '12px',
    },
  }[type]);

const Text = styled.div<ITextProps>(({ theme, type = 'p', weight = 'normal', align = 'left' }) => ({
  color: theme.colors.white,
  fontWeight: weight,
  textAlign: align,
  ...getStyleByType(type),
}));

export default Text;
